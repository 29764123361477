import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation, Link } from "react-router-dom";
const $ = require("jquery");
$.Datatable = require("datatables.net");

export default function View() {
  const location = useLocation();
  const path = location.pathname.split("/")[3];
  const [user, setUser] = useState();
  const [item, setVerify] = useState();
  const [challenge, setchallenge] = useState();
  const [combinedData, setCombinedData] = useState();
  const [txn, setTxn] = useState();
  const [bonusHis, setBonusHis] = useState();
  const [penaltyHis, setPenaltyHis] = useState();
  const [txnwith, setTxnwith] = useState(null);
  const [referral, setReferral] = useState();
  const [kyc, setKyc] = useState();
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }

  let [mismatchValue, setmismatchValue] = useState(0);
  let [HoldBalance, setHoldBalance] = useState(0);

  const getUser = () => {
    let access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };

    axios
      .get(baseUrl + `get_user/${path}`, { headers })
      .then((res) => {
        console.log(res.data);
        setUser(res.data);
        Allrefer(res.data.referralCode);
        setmismatchValue(res.data.holdBalance);
        setHoldBalance(res.data.holdBalance);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const [referCount, setRefercount] = useState([]);
  const Allrefer = async (id) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    await axios
      .get(baseUrl + `referral/code/${id}`, { headers })
      .then((res) => {
        setRefercount(res.data);
        console.log(res.data);
      });
  };

  const Allchallenge = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .get(baseUrl + `get_challange/user/${path}`, { headers })
      .then((res) => {
        setCombinedData(undefined);
        setTxn(undefined);
        setTxnwith(undefined);
        setReferral(undefined);
        setKyc(undefined);
        setPenaltyHis(undefined);
        setBonusHis(undefined);
        if (res.data.message) {
          console.log(res);
          setchallenge(undefined);
          $("table").dataTable();
          imageViewer();
        } else {
          console.log(res);
          setchallenge(res.data);
          $("table").dataTable();
          console.log("game datata is ", res.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const AllHistory = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .get(baseUrl + `get_history/user/${path}`, { headers })
      .then((res) => {
        setTxn(undefined);
        setTxnwith(undefined);
        setReferral(undefined);
        setKyc(undefined);
        setchallenge(undefined);
        setPenaltyHis(undefined);
        setBonusHis(undefined);
        const completedHistory = res.data.filter(
          (item) => item.status !== "none"
        );
        if (res.data.message) {
          console.log(res);
          setCombinedData(undefined);
          $("table").dataTable();
        } else {
          console.log(res);
          setCombinedData(completedHistory);
          $("table").dataTable();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const transactionHis = () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .get(baseUrl + `txn_history/user/${path}`, { headers })
      .then((res) => {
        setCombinedData(undefined);
        setchallenge(undefined);
        setReferral(undefined);
        setKyc(undefined);
        setTxnwith(undefined);
        setBonusHis(undefined);
        setPenaltyHis(undefined);
        if (res.data.message) {
          console.log(res);
          setTxn(undefined);
          $("table").dataTable();
          imageViewer();
        } else {
          console.log(res);
          setTxn(res.data);
          console.log("depositdata ", res.data);
          $("table").dataTable();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const BonusHis = () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .get(baseUrl + `bonus_history/user/${path}`, { headers })
      .then((res) => {
        setCombinedData(undefined);
        setchallenge(undefined);
        setReferral(undefined);
        setKyc(undefined);
        setTxnwith(undefined);
        setTxn(undefined);
        setPenaltyHis(undefined);
        if (res.data.message) {
          console.log(res);
          setBonusHis(undefined);
          $("table").dataTable();
          imageViewer();
        } else {
          console.log(res);
          setBonusHis(res.data);
          console.log("bonus data ", res.data);

          $("table").dataTable();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const PenaltyHis = () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .get(baseUrl + `penalty_history/user/${path}`, { headers })
      .then((res) => {
        setCombinedData(undefined);
        setchallenge(undefined);
        setReferral(undefined);
        setKyc(undefined);
        setTxnwith(undefined);
        setTxn(undefined);
        setBonusHis(undefined);
        if (res.data.message) {
          console.log(res);
          setPenaltyHis(undefined);
          $("table").dataTable();
          imageViewer();
        } else {
          console.log(res);
          setPenaltyHis(res.data);
          console.log("penalty data ", res.data);
          $("table").dataTable();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const withdrawalHis = () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .get(baseUrl + `txnwith_history/user/${path}`, { headers })
      .then((res) => {
        setCombinedData(undefined);
        setTxn(undefined);
        setchallenge(undefined);
        setReferral(undefined);
        setKyc(undefined);
        setPenaltyHis(undefined);
        setBonusHis(undefined);
        if (res.data.message) {
          setTxnwith(undefined);
          $("table").dataTable();
          imageViewer();
        } else {
          setTxnwith(res.data);
          console.log("hello srk", res.data);
          $("table").dataTable();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const dateFormat = (e) => {
    const date = new Date(e);
    const newDate = date.toLocaleString("default", {
      month: "long",
      day: "numeric",
      hour: "numeric",
      hour12: true,
      minute: "numeric",
    });
    return newDate;
  };

  const referralHis = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    console.log("at front end :- ", user.referralCode);
    await axios
      .get(baseUrl + `referral/code/win/${user.referralCode}`, { headers })
      .then((res) => {
        setCombinedData(undefined);
        setchallenge(undefined);
        setTxn(undefined);
        setTxnwith(undefined);
        setKyc(undefined);
        setPenaltyHis(undefined);
        setBonusHis(undefined);
        if (res.data.message) {
          setReferral(undefined);
          console.log(res.data);
          $("table").dataTable();
          imageViewer();
        } else {
          setReferral(res.data);
          // console.log("refferal data===========", res.data);
          $("table").dataTable();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getKyc = () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios.get(baseUrl + `admin/user/kyc/${path}`, { headers }).then((res) => {
      setCombinedData(undefined);
      setTxn(undefined);
      setTxnwith(undefined);
      setReferral(undefined);
      setchallenge(undefined);
      setPenaltyHis(undefined);
      setBonusHis(undefined);
      if (res.data.message) {
        setKyc(undefined);
        console.log(res.data);
        $("table").dataTable();
        imageViewer();
      } else {
        setKyc(res.data);
        console.log(res.data);
        $("table").dataTable();
        imageViewer();
      }
    });
  };

  function imageViewer() {
    let imgs = document.getElementsByClassName("img"),
      out = document.getElementsByClassName("img-out")[0];
    for (let i = 0; i < imgs.length; i++) {
      if (!imgs[i].classList.contains("el")) {
        imgs[i].classList.add("el");
        imgs[i].addEventListener("click", lightImage);
        function lightImage() {
          let container = document.getElementsByClassName("img-panel")[i];
          container.classList.toggle("img-panel__selct");
        }

        imgs[i].addEventListener("click", openImage);
        function openImage() {
          let imgElement = document.createElement("img"),
            imgWrapper = document.createElement("div"),
            imgClose = document.createElement("div"),
            container = document.getElementsByClassName("img-panel")[i];
          container.classList.add("img-panel__selct");
          imgElement.setAttribute("class", "image__selected");
          imgElement.src = imgs[i].src;
          imgWrapper.setAttribute("class", "img-wrapper");
          imgClose.setAttribute("class", "img-close");
          imgWrapper.appendChild(imgElement);
          imgWrapper.appendChild(imgClose);

          setTimeout(function () {
            imgWrapper.classList.add("img-wrapper__initial");
            imgElement.classList.add("img-selected-initial");
          }, 50);

          out.appendChild(imgWrapper);
          imgClose.addEventListener("click", function () {
            container.classList.remove("img-panel__selct");
            out.removeChild(imgWrapper);
          });
        }
      }
    }
  }
  const update = (Id) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .patch(
        baseUrl + `aadharcard/${Id}`,
        { verified: "verified" },
        { headers }
      )
      .then((res) => {
        getUser();
        getKyc();
      });
  };

  const updateMismatch = (Id) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .patch(baseUrl + `user/missmatch/clear/${Id}`, { headers })
      .then((res) => {
        getUser();
        console.log(res);
      });
  };

  const updateHold = (Id) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios.patch(baseUrl + `user/Hold/clear/${Id}`, { headers }).then((res) => {
      getUser();
      console.log(res);
    });
  };

  const checkfailedpayout = (txn_id, referenceId) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .post(
        baseUrl + `payout/response/api`,
        { txn_id, referenceId },
        { headers }
      )
      .then((res) => {
        const icon = res.data.status === "SUCCESS" ? "success" : "danger";
        const title =
          res.data.status === "SUCCESS"
            ? "Withdraw successfully"
            : "Transaction Proccessing or Failed";
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
          window.location.reload();
        }
      });
  };

  const deletedata = (Id) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .patch(baseUrl + `aadharcard/${Id}`, { verified: "reject" }, { headers })
      .then((res) => {
        getUser();
        getKyc();
      });
  };
  useEffect(() => {
    getUser();
    Allchallenge();
    AllHistory();
    getKyc();
  }, [baseUrl, path]);

  return (
    <>
      {Boolean(user) && (
        <>
          <div className="img-out"></div>
          <div className="row">
            <div className="col-lg-6 mb-4">
              <div className="card shadow-lg h-100 ">
                <div className="card-header">User Details</div>
                <ul className="list-group list-group-flush">
                  {user && user.avatar && (
                    <li className="list-group-item img-panel">
                      Profile Pic :
                      <img
                        className="img"
                        src={baseUrl + `${user.avatar}`}
                        alt="Profile"
                        style={{
                          borderRadius: "5px",
                          width: "4rem",
                          height: "4rem",
                        }}
                      />{" "}
                    </li>
                  )}
                  <li className="list-group-item">name : {user.name}</li>

                  <li className="list-group-item">
                    verified :
                    <span
                      className={`badge badge-pill ${
                        user.verified === "verified"
                          ? "badge-success"
                          : "badge-danger"
                      }`}
                    >
                      {user.verified}
                    </span>
                  </li>
                  <li className="list-group-item">phone : {user.phone}</li>

                  <li className="list-group-item">
                    Wallet balance : {user.walletBalance}
                  </li>
                  <li className="list-group-item">
                    Withdrawal Amount balance : {user.withdrawAmount}
                  </li>
                  <li className="list-group-item">
                    Withdrawal Hold balance : {user.withdrawHoldBalance}
                    <div class="col" style={{ color: "#8c8c8c" }}>
                      Note: If hold balance not 0 user can't make new Withdrawal
                      request
                    </div>
                  </li>
                  <li className="list-group-item">
                    Referral balance : {user.referralWallet}
                  </li>
                  <li className="list-group-item">
                    {/* Referral count : {referCount && referCount} */}
                  </li>
                  <li className="list-group-item">
                    Referral earning : {user.referralEarning}
                  </li>
                  <li className="list-group-item">
                    total Deposit : {user.totalDeposit}
                  </li>
                  <li className="list-group-item">
                    total Withdrawal : {user.totalWithdrawal}
                  </li>
                  <li className="list-group-item">
                    total Bonus : {user.totalBonus}
                  </li>
                  <li className="list-group-item">
                    total Penalty : {user.totalPenalty}
                  </li>
                  <li className="list-group-item">
                    hold balance : {HoldBalance}
                  </li>
                  <li className="list-group-item">
                    Won amount : {user.wonAmount}
                  </li>
                  <li className="list-group-item">
                    Lose amount : {user.loseAmount}
                  </li>
                  {/* <li className="list-group-item">
                    Calculated wallet balance :{" "}
                    {user.wonAmount -
                      user.loseAmount +
                      user.totalDeposit +
                      user.referralEarning +
                      user.totalBonus -
                      (user.totalWithdrawal +
                        user.referralWallet +
                        user.withdrawHoldBalance +
                        user.holdBalance +
                        user.totalPenalty)}
                  </li> */}
                  <li className="list-group-item">
                    Mismatch wallet balance : {mismatchValue}
                  </li>
                  <li className="list-group-item">
                    Referral code : {user.referralCode}
                  </li>
                  <li className="list-group-item">
                    Referral by : {user.referredBy}
                  </li>

                  <li className="list-group-item">
                    Account created at :{" "}
                    {dateFormat(user.createdAt).split(",")[0]}
                  </li>
                  <li className="list-group-item">
                    Account updated at :{" "}
                    {dateFormat(user.updatedAt).split(",")[0]}
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 mb-4">
              <div className="card shadow-lg h-100 ">
                <div className="card-header">Bank Details</div>
                <ul className="list-group list-group-flush">
                  <li className="list-group-item">
                    Account holder name : {user.holderName}
                  </li>
                  <li className="list-group-item">
                    IFSC code : {user.ifscCode}
                  </li>
                  <li className="list-group-item">
                    Account number : {user.accountNumber}
                  </li>
                  <li className="list-group-item">UPI ID : {user.upiId}</li>
                </ul>
                <ul className="list-group list-group-flush">
                  <li className="list-group-item">
                    Mismatch:{" "}
                    <button
                      onClick={() => updateMismatch(user._id)}
                      className="btn btn-success"
                    >
                      CLEAR ({mismatchValue})
                    </button>
                  </li>
                  <li className="list-group-item">
                    Hold :{" "}
                    <button
                      onClick={() => updateHold(user._id)}
                      className="btn btn-success"
                    >
                      CLEAR ({HoldBalance})
                    </button>{" "}
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="row ">
            <div className="col-12 grid-margin">
              <div className="card">
                <div className="card-body">
                  <button
                    className={
                      Boolean(combinedData)
                        ? `btn btn-success mb-3 mr-2`
                        : `btn btn-info mb-3 mr-2`
                    }
                    onClick={AllHistory}
                  >
                    All History
                  </button>
                  <button
                    className={
                      Boolean(challenge)
                        ? `btn btn-success mb-3 mr-2`
                        : `btn btn-info mb-3 mr-2`
                    }
                    onClick={Allchallenge}
                  >
                    Game History
                  </button>

                  <button
                    className={
                      Boolean(txn)
                        ? `btn btn-success mb-3 mr-2`
                        : `btn btn-info mb-3 mr-2`
                    }
                    onClick={transactionHis}
                  >
                    Deposit History
                  </button>
                  <button
                    className={
                      Boolean(bonusHis)
                        ? `btn btn-success mb-3 mr-2`
                        : `btn btn-info mb-3 mr-2`
                    }
                    onClick={BonusHis}
                  >
                    Bonus History
                  </button>
                  <button
                    className={
                      Boolean(penaltyHis)
                        ? `btn btn-success mb-3 mr-2`
                        : `btn btn-info mb-3 mr-2`
                    }
                    onClick={PenaltyHis}
                  >
                    Penalty History
                  </button>
                  <button
                    className={
                      Boolean(txnwith)
                        ? `btn btn-success mb-3 mr-2`
                        : `btn btn-info mb-3 mr-2`
                    }
                    onClick={withdrawalHis}
                  >
                    withdrawal History
                  </button>
                  <button
                    className={
                      Boolean(referral)
                        ? `btn btn-success mb-3 mr-2`
                        : `btn btn-info mb-3 mr-2`
                    }
                    onClick={referralHis}
                  >
                    Referral History
                  </button>
                  <button
                    className={
                      Boolean(kyc)
                        ? `btn btn-success mb-3 mr-2`
                        : `btn btn-info mb-3 mr-2`
                    }
                    onClick={getKyc}
                  >
                    KYC
                  </button>
                  {Boolean(combinedData) && (
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>ID</th>
                            <th>Creator</th>
                            <th>Accepter</th>
                            <th>Amount</th>
                            <th>Win/Lose Amt</th>
                            <th>Closing Balance</th>
                            <th>Status</th>
                            <th>Type</th>
                            <th>Winner</th>
                            {/* <th>Refferal_Earn_By</th> */}
                            <th>Date</th>
                            <th>Action</th>
                            <th>View</th>
                          </tr>
                        </thead>

                        <tbody>
                          {combinedData.map((item, key) => (
                            <tr key={key}>
                              <td>{key + 1}</td>
                              <td>{item._id}</td>
                              <td
                                style={{
                                  color: !item.createdBy ? "red" : "inherit",
                                  fontWeight: !item.createdBy
                                    ? "bold"
                                    : "normal",
                                }}
                              >
                                {item.createdBy ? item.createdBy.name : "N/A"}
                              </td>
                              <td
                                style={{
                                  color: !item.acceptedBy ? "red" : "inherit",
                                  fontWeight: !item.acceptedBy
                                    ? "bold"
                                    : "normal",
                                }}
                              >
                                {item.acceptedBy ? item.acceptedBy.name : "N/A"}
                              </td>

                              <td>{item.gameAmount || item.amount || 0}</td>

                              <td
                                style={{
                                  color: (() => {
                                    const amount = item
                                      ? item.winner
                                        ? user._id === item.winner?._id
                                          ? item.winAmount ?? 0
                                          : -(item.gameAmount ?? 0)
                                        : user._id === item.createdBy?._id
                                        ? item.winAmount ?? 0
                                        : -(item.winAmount ?? 0)
                                      : 0;

                                    return amount < 0
                                      ? "red"
                                      : "" || amount > 0
                                      ? "green"
                                      : "";
                                  })(),
                                  fontWeight: "bold",
                                }}
                              >
                                {item
                                  ? item.winner
                                    ? user._id === item.winner?._id
                                      ? (item.winAmount ?? 0) >= 0
                                        ? `Win: ${item.winAmount ?? 0}`
                                        : `Loss: ${-(item.winAmount ?? 0)}`
                                      : (item.gameAmount ?? 0) >= 0
                                      ? `Loss: ${item.gameAmount ?? 0}`
                                      : `Win: ${-(item.gameAmount ?? 0)}`
                                    : user._id === item.createdBy?._id
                                    ? (item.winAmount ?? 0) >= 0
                                      ? `Win: ${item.winAmount ?? 0}`
                                      : `Loss: ${-(item.winAmount ?? 0)}`
                                    : `Win/Loss: ${item.winAmount ?? 0}`
                                  : "0"}
                              </td>

                              <td>
                                {item.closingBalance
                                  ? item.closingBalance ?? 0
                                  : item.createdBy &&
                                    item.createdBy._id === user?._id
                                  ? item.Creator_closingbalance ?? 0
                                  : item.acceptedBy &&
                                    item.acceptedBy._id === user?._id
                                  ? item.Acceptor_closingbalance ?? 0
                                  : 0}
                              </td>
                              <td
                                style={{
                                  color:
                                    item.status === "cancelled" ||
                                    item.status === "Penalty by Admin" ||
                                    item.status === "FAILED"
                                      ? "red"
                                      : "green",
                                  fontWeight: "bold",
                                }}
                              >
                                {item.status
                                  ? item.status
                                  : `Referral earned by ${item.earnedFrom.name}`}
                              </td>

                              <td>
                                {item.reqType ??
                                  (item.gameType
                                    ? decodeURIComponent(item.gameType)
                                    : "Referral")}
                              </td>

                              <td
                                style={{
                                  color: !item.winner ? "red" : "Green",
                                  fontWeight: !item.winner ? "bold" : "bold",
                                }}
                              >
                                {item.winner ? item.winner.name : "N/A"}
                              </td>

                              {/* <td>
                                {item.earnedFrom
                                  ? item.earnedFrom.name
                                  : "No Referral"}
                              </td> */}

                              <td>{dateFormat(item.updatedAt)}</td>
                              <td>
                                {
                                  //&& data.status != 'FAILED'
                                  item.status !== "SUCCESS" &&
                                  item.amount === user.withdrawHoldBalance ? (
                                    <button
                                      className="btn btn-danger"
                                      onClick={() =>
                                        checkfailedpayout(
                                          item.paymentGateway,
                                          item._id,
                                          item.referenceId
                                        )
                                      }
                                    >
                                      Check {item.paymentGateway}
                                    </button>
                                  ) : (
                                    "Checked"
                                  )
                                }
                              </td>
                              <td>
                                {item.gameType ? (
                                  <Link
                                    type="button"
                                    className="btn btn-primary mx-1"
                                    to={`/view/${item._id}`}
                                  >
                                    View
                                  </Link>
                                ) : null}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                  {Boolean(challenge) && (
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th> ID</th>
                            <th> Creator</th>
                            <th> Accepter</th>
                            <th> Amount </th>
                            <th> Win/Lose Amt </th>
                            <th> Closing balance</th>
                            <th> Status </th>
                            <th> Game Type </th>
                            <th> Winner </th>
                            <th> Date </th>
                            <th> Action </th>
                          </tr>
                        </thead>

                        <tbody>
                          {challenge.map((game, key) => (
                            <tr key={key}>
                              <td>{key + 1}</td>
                              <td>{game._id}</td>
                              <td>
                                <span className="pl-2 ">
                                  {game.createdBy ? game.createdBy.name : null}
                                </span>
                              </td>

                              <td>
                                <span className="pl-2">
                                  {game.acceptedBy
                                    ? game.acceptedBy.name
                                    : null}
                                </span>
                              </td>
                              <td>{game.gameAmount ?? 0}</td>
                              <td>
                                {game?.winner
                                  ? user._id === game.winner._id
                                    ? `+${game.winAmount ?? 0}`
                                    : `-${game.gameAmount ?? 0}`
                                  : user._id === game.createdBy._id
                                  ? `${game.winAmount ?? 0}`
                                  : `-${game.winAmount ?? 0}`}
                              </td>

                              <td>
                                {game.winner
                                  ? user._id === game.winner._id
                                    ? game.winnerClosingBalance ?? 0
                                    : game.loserClosingBalance ?? 0
                                  : user._id === game.createdBy._id
                                  ? game.loserClosingBalance ?? 0
                                  : game.winnerClosingBalance ?? 0}
                              </td>

                              <td className="text-primary font-weight-bold">
                                {game.status}
                              </td>
                              <td>
                                {/* {game.gameType} */}
                                {decodeURIComponent(game.gameType)}
                              </td>
                              <td>{game.winner ? game.winner.name : "N/A"}</td>
                              <td>
                                {dateFormat(game.updatedAt).split(",")[0]}{" "}
                              </td>
                              <td>
                                <Link
                                  type="button"
                                  className="btn btn-primary mx-1"
                                  to={`/view/${game._id}`}
                                >
                                  View
                                </Link>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                  {Boolean(txn) && (
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th> ID</th>

                            <th> Amount </th>
                            <th> Closing balance</th>
                            <th> Status </th>
                            <th> Payment gatway </th>
                            <th> Date </th>
                          </tr>
                        </thead>
                        <tbody>
                          {txn.map((data, key) => (
                            <tr key={key}>
                              <td>{key + 1}</td>
                              <td>{data._id}</td>

                              <td>{data.amount ?? 0}</td>
                              <td> {data.closingBalance ?? 0}</td>

                              <td className="font-weight-bold text-success">
                                {data.status}
                              </td>
                              <td>
                                {" "}
                                {data.paymentGateway ? data.paymentGateway : ""}
                              </td>
                              <td>
                                {dateFormat(data.updatedAt).split(",")[0]}{" "}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                  {Boolean(bonusHis) && (
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th> ID</th>

                            <th> Amount </th>
                            <th> Closing balance</th>
                            <th> Status </th>
                            <th> Payment gatway </th>
                            <th> Date </th>
                          </tr>
                        </thead>
                        <tbody>
                          {bonusHis.map((data, key) => (
                            <tr key={key}>
                              <td>{key + 1}</td>
                              <td>{data._id}</td>

                              <td>{data.amount ?? 0}</td>
                              <td> {data.closingBalance ?? 0}</td>

                              <td className="font-weight-bold text-success">
                                {data.status}
                              </td>
                              <td>
                                {" "}
                                {data.paymentGateway ? data.paymentGateway : ""}
                              </td>
                              <td>
                                {dateFormat(data.updatedAt).split(",")[0]}{" "}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                  {Boolean(penaltyHis) && (
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th> ID</th>

                            <th> Amount </th>
                            <th> Closing balance</th>
                            <th> Status </th>
                            <th> Payment gatway </th>
                            <th> Date </th>
                          </tr>
                        </thead>
                        <tbody>
                          {penaltyHis.map((data, key) => (
                            <tr key={key}>
                              <td>{key + 1}</td>
                              <td>{data._id}</td>

                              <td>{data.amount ?? 0}</td>
                              <td> {data.closingBalance ?? 0}</td>

                              <td className="font-weight-bold text-success">
                                {data.status}
                              </td>
                              <td>
                                {" "}
                                {data.paymentGateway ? data.paymentGateway : ""}
                              </td>
                              <td>
                                {dateFormat(data.updatedAt).split(",")[0]}{" "}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                  {Boolean(txnwith) && (
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th> ID</th>

                            <th> Type</th>
                            <th> Amount </th>
                            <th> Closing balance</th>
                            <th> Status </th>
                            <th> Action </th>
                            <th> Date </th>
                          </tr>
                        </thead>
                        <tbody>
                          {txnwith &&
                            txnwith.map((data, key) => (
                              <tr key={key}>
                                <td>{key + 1}</td>
                                <td>{data._id}</td>

                                <td>
                                  <span className="pl-2">
                                    {data.withdrawType ?? null}
                                  </span>
                                </td>
                                <td>{data.amount ?? 0}</td>
                                <td> {data.closingBalance ?? 0}</td>
                                <td className="font-weight-bold text-success">
                                  {data.status}
                                </td>
                                <td>
                                  {
                                    //&& data.status != 'FAILED'
                                    data.status !== "SUCCESS" &&
                                    data.amount === user.withdrawHoldBalance ? (
                                      <button
                                        className="btn btn-danger"
                                        onClick={() =>
                                          checkfailedpayout(
                                            data.paymentGateway,
                                            data._id,
                                            data.referenceId
                                          )
                                        }
                                      >
                                        Check {data.paymentGateway}
                                      </button>
                                    ) : (
                                      "Checked"
                                    )
                                  }
                                </td>
                                <td>
                                  {dateFormat(data.updatedAt).split(",")[0]}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                  {Boolean(referral) && (
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th> ID</th>
                            <th> Earned by</th>
                            <th> Amount </th>
                            <th> Closing balance</th>
                            <th> Date </th>
                          </tr>
                        </thead>
                        <tbody>
                          {referral.map((data, key) => (
                            <tr key={key}>
                              <td>{key + 1}</td>
                              <td>{data._id}</td>
                              <td>
                                {data.earnedFrom ? data.earnedFrom.name : null}
                              </td>
                              <td>{data.amount ?? 0}</td>
                              <td>{data.closingBalance ?? 0}</td>
                              <td>
                                {dateFormat(data.updatedAt).split(",")[0]}{" "}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                  {Boolean(kyc) && (
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th> ID</th>

                            <th>Doc name</th>
                            <th> Aadhar no</th>

                            <th>DOB</th>
                            <th> Document-Front</th>
                            <th> Document-Back</th>
                            <th> Status </th>
                            <th>Date</th>
                            <th> Approve or Reject </th>
                            {/* <th> View </th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {kyc &&
                            kyc.map((item, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{item._id}</td>

                                <td>{item.name}</td>

                                <td>{item.number}</td>
                                <td>{item.dob ?? "not available"}</td>
                                <td>
                                  <div className="img-panel">
                                    <img
                                      className="img"
                                      src={baseUrl + `${item.front}`}
                                      alt="kyc"
                                      style={{
                                        borderRadius: "5px",
                                        width: "4rem",
                                        height: "4rem",
                                      }}
                                      id={`kyc${index}`}
                                    />
                                  </div>
                                </td>
                                <td>
                                  <div className="img-panel">
                                    <img
                                      className="img"
                                      src={baseUrl + `${item.back}`}
                                      alt="kyc"
                                      style={{
                                        borderRadius: "5px",
                                        width: "4rem",
                                        height: "4rem",
                                      }}
                                      id={`kyc${index}`}
                                    />
                                  </div>
                                </td>

                                <td>
                                  <span
                                    className={
                                      item.verified === "reject"
                                        ? "btn btn-danger"
                                        : "btn btn-success"
                                    }
                                  >
                                    {item.verified}
                                  </span>{" "}
                                </td>
                                <td>
                                  {dateFormat(item.updatedAt).split(",")[0]}{" "}
                                </td>
                                <td>
                                  <button
                                    className="btn btn-success mr-1"
                                    disabled={
                                      item.verified === "verified"
                                        ? true
                                        : false
                                    }
                                    onClick={() => update(item._id)}
                                  >
                                    Approve
                                  </button>
                                  <button
                                    className="btn btn-danger"
                                    disabled={
                                      item.verified === "verified"
                                        ? false
                                        : false
                                    }
                                    onClick={() => deletedata(item._id)}
                                  >
                                    Reject
                                  </button>
                                </td>
                                {/* <td><Link to="/user/view" className="btn btn-primary">View</Link></td> */}
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
