import React, { useEffect, useState } from "react";
import axios from "axios";
import Gateway from "./Gateway";
import ReactSwitch from "react-switch";

export const Sitesettings = () => {
  const [webTitle, setWebTitle] = useState("");

  const [referPercentage, setreferPercentage] = useState("");
  const [adminPercentage50To400, setadminPercentage50To400] = useState("");
  const [adminPercentage400To, setadminPercentage400To] = useState("");

  const [manualUpi, setmanualUPI] = useState("");

  const [isPaymentMethod, issetPaymentMethod] = useState(true);
  const [isAutoApproveConflictBattle, setAutoApproveConflictBattle] = useState();

  const [whatsappNumber1, setWhatsappNumber1] = useState("");
  const [whatsappNumber2, setWhatsappNumber2] = useState("");

  const [whatsappNumber1ActiveInactive, setWhatsappNumber1ActiveInactive] = useState(false);
  const [whatsappNumber2ActiveInactive, setWhatsappNumber2ActiveInactive] = useState(false);

  const [websiteName, setWebName] = useState("");
  const [msg, setMsg] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [companyMobile, setCompanyMobile] = useState("");
  const [companyEmail, setCompanyEmail] = useState("");
  const [companyWebsite, setCompanyWebsite] = useState("");
  const [roomCode, setRoomCode] = useState("");
  const [RoomCodeUrl, setRoomCodeUrl] = useState("");
  const [Logo, setLogo] = useState("");
  const [SmallLogo, setSmallLogo] = useState("");

  const [LandingImage1, setLandingImage1] = useState("");
  const [LandingImage2, setLandingImage2] = useState("");
  const [LandingImage3, setLandingImage3] = useState("");
  const [LandingImage4, setLandingImage4] = useState("");

  const [isLandingImage1, issetLandingImage1] = useState(true);
  const [isLandingImage2, issetLandingImage2] = useState(true);
  const [isLandingImage3, issetLandingImage3] = useState(true);
  const [isLandingImage4, issetLandingImage4] = useState(true);
  const [checkedRoom, setCheckedRoom] = useState(true);
  const [checkedAutoPayout, setCheckedAutoPayout] = useState(false);

  const [version, setVersion] = useState("");

  const [settingId, setSettingId] = useState("");

  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }

  function fetchSiteSettingData(){
   axios.get(baseUrl + "settings/data", {}).then((res) => {
      console.log(res.data);
      setSettingId(res.data._id ? res.data._id : "");
      setWebTitle(res.data.webTitle);

      setreferPercentage(res.data.referPercentage);
      setadminPercentage50To400(res.data.adminPercentage50To400);
      setadminPercentage400To(res.data.adminPercentage400To);

      setmanualUPI(res.data.manualUpi);
      issetPaymentMethod(res.data.isPaymentMethod);
      setAutoApproveConflictBattle(res.data.autoApproveConflictBattle);
      console.log(res.data.autoApproveConflictBattle);

      setCheckedRoom(res.data.roomcodeAuto);
      setCheckedAutoPayout(res.data.autoPayoutEnabled || false);
      setWebName(res.data.websiteName);

      setWhatsappNumber1(res.data.whatsapp[0].number);
      setWhatsappNumber2(res.data.whatsapp[1].number);
      setWhatsappNumber1ActiveInactive(res.data.whatsapp[0].active);
      setWhatsappNumber2ActiveInactive(res.data.whatsapp[1].active);

      setMsg(res.data.msg);
      setCompanyName(res.data.companyName);
      setCompanyAddress(res.data.companyAddress);
      setCompanyMobile(res.data.companyMobile);
      setCompanyEmail(res.data.companyEmail);
      setCompanyWebsite(res.data.companyWebsite);
      setRoomCode(res.data.roomCodeUrl);
      setRoomCodeUrl(res.data.roomCodeUrlPopular);
      setLogo(res.data.logo);
      setSmallLogo(res.data.smallLogo);
      setLandingImage1(res.data.landingImage1);
      setLandingImage2(res.data.landingImage2);
      setLandingImage3(res.data.landingImage3);
      setLandingImage4(res.data.landingImage4);
      issetLandingImage1(res.data.isLandingImage1);
      issetLandingImage2(res.data.isLandingImage2);
      issetLandingImage3(res.data.isLandingImage3);
      issetLandingImage4(res.data.isLandingImage4);
      setVersion(res.data.version);
    });
  }

  useEffect(() => {
    fetchSiteSettingData();
  }, []);


  let whatsapp = [
    {
      number: whatsappNumber1,
      active: whatsappNumber1ActiveInactive,
    },
    {
      number: whatsappNumber2,
      active: whatsappNumber2ActiveInactive,
    }
  ]

  console.log(isAutoApproveConflictBattle)

  const handleSubmit = async (e) => {
    console.log(whatsapp)
    e.preventDefault();
    let formData = new FormData();
    formData.append("settingId", settingId);
    formData.append("webTitle", webTitle);
    formData.append("referPercentage", referPercentage);
    formData.append("adminPercentage50To400", adminPercentage50To400);
    formData.append("adminPercentage400To", adminPercentage400To);
    formData.append("manualUpi", manualUpi);
    formData.append("isPaymentMethod", isPaymentMethod);
    formData.append("autoApproveConflictBattle", isAutoApproveConflictBattle);
    formData.append("websiteName", websiteName);
    formData.append("whatsapp", JSON.stringify(whatsapp));
    formData.append("msg", msg);
    formData.append("companyName", companyName);
    formData.append("companyAddress", companyAddress);
    formData.append("companyMobile", companyMobile);
    formData.append("companyEmail", companyEmail);
    formData.append("companyWebsite", companyWebsite);
    formData.append("roomCodeUrl", roomCode);
    formData.append("roomCodeUrlPopular", RoomCodeUrl);
    formData.append("roomCodeAuto", checkedRoom);
    formData.append("autoPayoutEnabled", checkedAutoPayout);
    formData.append("logo", Logo);
    formData.append("smallLogo", SmallLogo);
    formData.append("landingImage1", LandingImage1);
    formData.append("landingImage2", LandingImage2);
    formData.append("landingImage3", LandingImage3);
    formData.append("landingImage4", LandingImage4);
    formData.append("isLandingImage1", isLandingImage1);
    formData.append("isLandingImage2", isLandingImage2);
    formData.append("isLandingImage3", isLandingImage3);
    formData.append("isLandingImage4", isLandingImage4);
    formData.append("version", version);
    const response = await axios.post(baseUrl + `settings`, formData);
    console.log(response.data);
    if (response.data.status === "success") {
      fetchSiteSettingData();
      alert("Settings submitted successfully");
    } else {
      alert("Settings Not Submitted");
    }
  };

  useEffect(() => {
    const Logo1 = document.getElementById("Logo");
    const Logo2 = document.getElementById("SmallLogo");
    const LandingImage1 = document.getElementById("LandingImage1");
    const LandingImage2 = document.getElementById("LandingImage2");
    const LandingImage3 = document.getElementById("LandingImage3");
    const LandingImage4 = document.getElementById("LandingImage4");

    Logo1.onchange = (e) => {
      const [file] = Logo1.files;
      setLogo(file);
    };
    Logo2.onchange = (e) => {
      const [file] = Logo2.files;
      setSmallLogo(file);
    };
    LandingImage1.onchange = (e) => {
      const [file] = LandingImage1.files;
      setLandingImage1(file);
    };
    LandingImage2.onchange = (e) => {
      const [file] = LandingImage2.files;
      setLandingImage2(file);
    };
    LandingImage3.onchange = (e) => {
      const [file] = LandingImage3.files;
      setLandingImage3(file);
    };
    LandingImage4.onchange = (e) => {
      const [file] = LandingImage4.files;
      setLandingImage4(file);
    };
  }, []);

  const handleChangeRoom = (val) => {
    setCheckedRoom(val);
  };

  const handleChangePayout = (val) => {
    setCheckedAutoPayout(val);
  };

  return (
    <>
      <h3 className="text-uppercase font-weight-bold my-3 text-white">
        Website Settings
      </h3>

      <h4 className="text-uppercase font-weight-bold my-3 text-light">
        UI Settings
      </h4>
      <form
        onSubmit={handleSubmit}
        method="patch"
        encType="multipart/form-data"
        style={{ backgroundColor: "rgba(0, 27, 11, 0.734)" }}
      >
        <div className="form-row">
          <div className="form-group col-md-4">
            <label htmlFor="websiteName">Website Title</label>
            <input
              className="form-control"
              type="text"
              value={webTitle}
              onChange={(e) => setWebTitle(e.target.value)}
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-md-4">
            <label htmlFor="websiteName">Website name</label>
            <input
              className="form-control"
              type="text"
              value={websiteName}
              onChange={(e) => setWebName(e.target.value)}
            />
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="WebsiteName">Whatsapp Number 1</label>
            <input
              className="form-control"
              type="text"
              value={whatsappNumber1}
              onChange={(e) => setWhatsappNumber1(e.target.value)}
            />
            <select
              className="form-control"
              name=""
              id=""
              value={whatsappNumber1ActiveInactive}
              onChange={(e) => setWhatsappNumber1ActiveInactive(e.target.value)}
            >
              <option value="true">active </option>
              <option value="false">inactive </option>
            </select>
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="WebsiteName">Whatsapp Number 2</label>
            <input
              className="form-control"
              type="text"
              value={whatsappNumber2}
              onChange={(e) => setWhatsappNumber2(e.target.value)}
            />
            <select
              className="form-control"
              name=""
              id=""
              value={whatsappNumber2ActiveInactive}
              onChange={(e) => setWhatsappNumber2ActiveInactive(e.target.value)}
            >
              <option value="true">active </option>
              <option value="false">inactive </option>
            </select>
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-md-4">
            <label class="form-label font-weight-bold">Website Payment Method</label>
            <select
              className="form-control"
              name=""
              id=""
              value={isPaymentMethod}
              onChange={(e) => issetPaymentMethod(e.target.value)}
            >
              <option value="phonepe">Phonepe</option>
              <option value="manual">Manual Payment</option>
              <option value="both">All Payment Mode</option>
            </select> 
          </div>
          <div class="col-md-4">
            <label class="form-label font-weight-bold">Manual Payment UPI</label>
            <div class="input-group input-group-outline">
              <input
                className="form-control"
                type="text"
                value={manualUpi}
                onChange={(e) => setmanualUPI(e.target.value)}
              />
            </div>
          </div>
          <div class="col-md-4">
            
            <label class="form-label font-weight-bold">Auto Approve Conflict Battle</label>
            <select
              className="form-control"
              name=""
              id=""
              value={isAutoApproveConflictBattle}
              onChange={(e) => setAutoApproveConflictBattle(e.target.value)}
            >
              <option value="false">In Active</option>
              <option value="true">Active</option>
              
            </select> 
          </div>
        </div>

        <div className="form-row">

        <div class="col-md-4">
                        <label class="form-label font-weight-bold">User Refer Percentage</label>
                        <div class="input-group input-group-outline my-3 mt-1">
                          <input
                            className="form-control"
                            type="text"
                            value={referPercentage}
                            onChange={(e) => setreferPercentage(e.target.value)}
                          />
                        </div>
                      </div>

                      <div class="col-md-4">
                        <label class="form-label font-weight-bold">Commission (50 to 400)</label>
                        <div class="input-group input-group-outline my-3 mt-1">
                          <input
                            className="form-control"
                            type="text"
                            value={adminPercentage50To400}
                            onChange={(e) => setadminPercentage50To400(e.target.value)}
                          />
                        </div>
                      </div>

                      <div class="col-md-4">
                        <label class="form-label font-weight-bold">Commission (Greater than 400)</label>
                        <div class="input-group input-group-outline my-3 mt-1">
                          <input
                            className="form-control"
                            type="text"
                            value={adminPercentage400To}
                            onChange={(e) => setadminPercentage400To(e.target.value)}
                          />
                        </div>
                      </div>

        </div>

        <div className="form-row">
          <div className="form-group col-md-4">
            <label htmlFor="websiteName">Commpany name</label>
            <input
              className="form-control"
              type="text"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
            />
          </div>

          <div className="form-group col-md-4">
            <label htmlFor="websiteName">Commpany Address</label>
            <input
              className="form-control"
              type="text"
              value={companyAddress}
              onChange={(e) => setCompanyAddress(e.target.value)}
            />
          </div>

          <div className="form-group col-md-4">
            <label htmlFor="websiteName">Commpany Mobile</label>
            <input
              className="form-control"
              type="text"
              value={companyMobile}
              onChange={(e) => setCompanyMobile(e.target.value)}
            />
          </div>

          <div className="form-group col-md-4">
            <label htmlFor="WebsiteEmail">Commpany Email</label>
            <input
              className="form-control"
              type="text"
              value={companyEmail}
              onChange={(e) => setCompanyEmail(e.target.value)}
            />
          </div>

          <div className="form-group col-md-4">
            <label htmlFor="WebsiteWebsite">Commpany Website</label>
            <input
              className="form-control"
              type="text"
              value={companyWebsite}
              onChange={(e) => setCompanyWebsite(e.target.value)}
              placeholder="Enter Company Website"
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-md-4">
            <label htmlFor="websiteName">Right Logo</label>
            <input className="form-control" type="file" name="Logo" id="Logo" />
          </div>

          <div className="form-group col-md-4">
            <label htmlFor="websiteName">Left Logo</label>
            <input
              className="form-control"
              type="file"
              name="SmallLogo"
              id="SmallLogo"
            />
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="websiteName">Message</label>
            <input
              className="form-control"
              type="text"
              value={msg}
              onChange={(e) => setMsg(e.target.value)}
              placeholder="Msg"
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-md-4">
            <label htmlFor="websiteName">Game image (1) </label>
            <input
              className="form-control"
              type="file"
              name="LandingImage1"
              id="LandingImage1"
            />
            <select
              className="form-control"
              name=""
              id=""
              value={isLandingImage1}
              onChange={(e) => issetLandingImage1(e.target.value)}
            >
              <option value="true">on</option>
              <option value="false">off</option>
            </select>
          </div>

          <div className="form-group col-md-4">
            <label htmlFor="websiteName">Game image (2)</label>
            <input
              className="form-control"
              type="file"
              name="LandingImage2"
              id="LandingImage2"
            />
            <select
              className="form-control"
              name=""
              id=""
              value={isLandingImage2}
              onChange={(e) => issetLandingImage2(e.target.value)}
            >
              <option value="true">on</option>
              <option value="false">off</option>
            </select>
          </div>

          <div className="form-group col-md-4">
            <label htmlFor="websiteName">Game image (3)</label>
            <input
              className="form-control"
              type="file"
              name="LandingImage3"
              id="LandingImage3"
            />
            <select
              className="form-control"
              name=""
              id=""
              value={isLandingImage3}
              onChange={(e) => issetLandingImage3(e.target.value)}
            >
              <option value="true">on</option>
              <option value="false">off</option>
            </select>
          </div>

          <div className="form-group col-md-4">
            <label htmlFor="websiteName">Game image (2)</label>
            <input
              className="form-control"
              type="file"
              name="LandingImage4"
              id="LandingImage4"
            />
            <select
              className="form-control"
              name=""
              id=""
              value={isLandingImage4}
              onChange={(e) => issetLandingImage4(e.target.value)}
            >
              <option value="true">on</option>
              <option value="false">off</option>
            </select>
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-md-4">
            <label htmlFor="websiteName">version</label>

            <input
              className="form-control"
              type="text"
              value={version}
              onChange={(e) => setVersion(e.target.value)}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-2">
            <label htmlFor="autoroomcode" style={{ display: "block" }}>
              Auto roomcode
            </label>
            <ReactSwitch
              checked={checkedRoom}
              onChange={handleChangeRoom}
              style={{ display: "none" }}
            />
          </div>
          <div className="form-group col-md-2">
            <label htmlFor="autoroomcode" style={{ display: "block" }}>
              Auto Payout
            </label>
            <ReactSwitch
              checked={checkedAutoPayout}
              onChange={handleChangePayout}
              style={{ display: "none" }}
            />
          </div>
          <div className="form-group col-md-5">
            <label htmlFor="WebsiteWebsite">Classic Room Code Url</label>
            <input
              className="form-control"
              type="url"
              value={roomCode}
              onChange={(e) => setRoomCode(e.target.value)}
              placeholder="Enter Url"
            />
          </div>
          <div className="form-group col-md-5">
            <label htmlFor="WebsiteWebsite"> Popular Room Code Url </label>
            <input
              className="form-control"
              type="url"
              value={RoomCodeUrl}
              onChange={(e) => setRoomCodeUrl(e.target.value)}
              placeholder="Enter Url"
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-4">
            <button type="submit" className="btn btn-dark">
              submit
            </button>
          </div>
        </div>
      </form>
      <Gateway />
    </>
  );
};
